import { RenewalMethod } from "@app/products/hm/model";
import {
  postHMPremisesRegister,
  postHMPremisesRegisterInitial,
} from "@app/products/hm/premises/[id]/components/buttons/register-premises/api";
import { PremisesRegisterWorkflowInitialResponse } from "@app/products/hm/premises/[id]/components/buttons/register-premises/model";
import { HMPremisesRenewalOptionsDialog } from "@app/products/hm/premises/[id]/components/dialogs/register-premises/components/renewal-option/_index";
import { PremisesRegisterWorkflowRequest } from "@app/products/hm/premises/[id]/components/dialogs/register-premises/model";
import {
  HM_PREMISES_GET_SLIDER,
  HM_PREMISES_REGISTER_GET_INIT_SLIDER,
  HM_PREMISES_REGISTER_SLIDER,
  HM_PREMISES_RENEWAL_OPTION_DIALOG_PLACE_ID,
  PREMISES_MANAGE_ROUTE,
} from "@app/products/hm/premises/[id]/constant";
import {
  IPremisesFormSecurity,
  Premises,
  Premises_Status,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { ErrorMessageFromApi } from "@app/products/local-laws/[id]/components/buttons/save/constant";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import {
  DateFromDateTo,
  DateToFromDialogPlaceID,
} from "@common/dialog/date-to-from/model";
import { PickDebtorNumberDialog } from "@common/dialog/debtor-number/_index";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

export const HMPremisesRegister = observer(() => {
  const { dataForms, setMiddlewareSubmitFormOptions, submitFormGetData } =
    useFlexibleFormStore();
  const { cancelToken } = useCancelRequest();
  const { pushNotificationPortal } = useNotificationPortalStore();
  const isNew = useIsNew();
  const history = useHistory();
  const { settings } = useCommonCoreStore();

  const [isShowPickDebtorDialog, setIsShowPickDebtorDialog] = useState(false);
  const [isShowIssueRegistrationDialog, setIsShowIssueRegistrationDialog] =
    useState(false);
  const [isShowRenewalOptionDialog, setIsShowRenewalOptionDialog] =
    useState(false);
  const [contactId, setContactId] = useState<number>();
  const [objIssueRegistrationDate, setObjIssueRegistrationDate] =
    useState<DateFromDateTo>();
  const [objRenewalOption, setObjRenewalOption] = useState<IKeyValuePacket[]>();

  const premiseObj = dataForms?.GeneralForm as Svc_Premises;
  const premiseData = premiseObj?.Premises as Premises;
  const parentSection = dataForms?.ParentSection as IPremisesFormSecurity;

  // #region Get Setting Value
  const emailNotifyRegistration = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_EmailNotifyRegistration]
  );
  // #endRegion Get Setting Value

  const allowRegisterPremisesButton = useMemo(() => {
    return parentSection ? parentSection.AllowRegisterPremisesButton : true;
  }, [parentSection]);

  const hmPremisesRegisterInitialSlider = useFlexibleFetchData({
    alias: HM_PREMISES_REGISTER_GET_INIT_SLIDER,
    slides: [
      {
        fetch: ({ initialData }) => {
          const premisesValue: Premises = initialData;
          return postHMPremisesRegisterInitial(premisesValue, cancelToken());
        },
        handleSuccess: async ({ dataFromApi }) => {
          const response: APIResponse<
            IIdentityPacket<PremisesRegisterWorkflowInitialResponse>
          > = dataFromApi;
          const responseIdentityPacket: APIResponse<IdentityPacket> =
            dataFromApi;
          const premisesResponse = response?.data?.ReturnObj?.Premises;
          const premisesID = responseIdentityPacket?.data?.ID ?? "";
          if (premisesID && isNew) {
            const urlRedirect = PREMISES_MANAGE_ROUTE + "/" + premisesID;
            pushNotificationPortal({
              title: "Register premises successfully.",
              type: "success",
              route: urlRedirect,
              callBack() {
                history.replace(urlRedirect, {
                  isCallInitRegisterPremises: true,
                });
              },
            });
          } else {
            if (
              premisesResponse?.PremisesType?.RenewalMethod_ENUM ===
              RenewalMethod.None
            ) {
              // It doesn't show dialog
              await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
              pushNotificationPortal({
                title: "Register premises successfully.",
                type: "success",
              });
            } else if (
              premisesResponse?.PremisesType?.RenewalMethod_ENUM ===
              RenewalMethod.Anniversary
            ) {
              // Renewal options dialog
              setObjRenewalOption(
                response?.data?.ReturnObj?.AnniversaryOptionsPicker
                  ?.KeyValueDataSource ?? []
              );
              setIsShowRenewalOptionDialog(true);
            } else {
              // Setup data and open the issue registration dialog
              objIssueRegistration(response?.data?.ReturnObj);
            }
          }
        },
        handleError: ({ errorFromApi, initialData }) => {
          if (isNew && errorFromApi.error?.[0] === ErrorMessageFromApi) {
            // Show the pick debtor dialog
            const premises = initialData as Premises;
            setContactId(premises?.Proprietor?.Contact?.Contact_ID);
            setIsShowPickDebtorDialog(true);
          } else {
            pushNotificationPortal({
              title: "Load init register value failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          }
        },
      },
    ],
  });

  const hmPremisesRegisterSlider = useFlexibleFetchData({
    alias: HM_PREMISES_REGISTER_SLIDER,
    slides: [
      {
        fetch: ({ initialData }) => {
          const data: PremisesRegisterWorkflowRequest = initialData;
          return postHMPremisesRegister(data, cancelToken());
        },
        handleSuccess: async ({ dataFromApi }) => {
          setIsShowIssueRegistrationDialog(false);
          setIsShowRenewalOptionDialog(false);
          if (isNew) {
            if (dataFromApi?.data?.ID) {
              const urlRedirect =
                PREMISES_MANAGE_ROUTE + "/" + dataFromApi.data.ID;

              pushNotificationPortal({
                title: "Register premises successfully.",
                type: "success",
                route: urlRedirect,
                callBack() {
                  history.replace(urlRedirect);
                },
              });
            }
          } else {
            await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
            pushNotificationPortal({
              title: "Register premises successfully.",
              type: "success",
            });
          }
        },
        handleError: ({ errorFromApi }) => {
          const renewalMethod = premiseData?.PremisesType?.RenewalMethod_ENUM;
          let placeID: string | undefined = DateToFromDialogPlaceID;
          if (renewalMethod === RenewalMethod.Anniversary) {
            placeID = HM_PREMISES_RENEWAL_OPTION_DIALOG_PLACE_ID;
          } else if (renewalMethod === RenewalMethod.None) {
            placeID = undefined;
          }
          pushNotificationPortal({
            title: "Register premises failed.",
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
            placeId: placeID,
          });
        },
      },
    ],
  });

  const handleClickButton = async (_event: any, debtorNumber?: string) => {
    setMiddlewareSubmitFormOptions({ skipCheckModified: true });
    const dataFormSubmit = await submitFormGetData("GeneralForm");
    const premises = dataFormSubmit?.Premises as Premises;
    if (isNil(premises)) return;
    if (debtorNumber) {
      premises.DebtorNumber = debtorNumber;
    }

    await hmPremisesRegisterInitialSlider.fetchApi({ initialData: premises });
  };

  const handlePickDebtor = (value: RefNumberUsage[]) => {
    handleClickButton(null, value?.[0]?.Sys_DebtorNumber);
    setIsShowPickDebtorDialog(false);
  };

  const handleSubmitPremisesRegister = async (value: any) => {
    setMiddlewareSubmitFormOptions({ skipCheckModified: true });
    const dataFormSubmit = await submitFormGetData("GeneralForm");
    const premises = dataFormSubmit?.Premises as Premises;
    if (premises) {
      hmPremisesRegisterSlider.fetchApi({
        initialData: {
          ...value,
          Premises: premises,
        } as PremisesRegisterWorkflowRequest,
      });
    }
  };

  const objIssueRegistration = (
    initValue: PremisesRegisterWorkflowInitialResponse
  ) => {
    const objRegister = new DateFromDateTo();
    objRegister.Title_Text = "Issue Registration";
    objRegister.DateLabelFrom_Text = "Registration from";
    objRegister.DateLabelFrom_To = "Registration to";
    objRegister.DateFrom = !isNil(initValue?.DateFrom)
      ? new Date(initValue?.DateFrom)
      : undefined;
    objRegister.DateTo = !isNil(initValue?.DateTo)
      ? new Date(initValue?.DateTo)
      : undefined;

    if (emailNotifyRegistration) {
      objRegister.NotifyText =
        "Notify [" + emailNotifyRegistration + "] of this registration?";
      objRegister.ShowNotification = true;
      objRegister.SendNotification = true;
    }

    setObjIssueRegistrationDate(objRegister);
    setIsShowIssueRegistrationDialog(true);
  };

  const handleSubmitIssueRegistration = (value: DateFromDateTo) => {
    handleSubmitPremisesRegister({
      DateFrom: value?.DateFrom,
      DateTo: value?.DateTo,
      SendNotification: value?.SendNotification,
    });
  };

  const handleSubmitRenewalOption = (value: any) => {
    handleSubmitPremisesRegister({
      AnniversaryYear: value?.AnniversaryYear,
    });
  };

  return premiseData?.Status_ENUM === Premises_Status.Prelodgement &&
    allowRegisterPremisesButton ? (
    <>
      <Loading
        isLoading={hmPremisesRegisterInitialSlider.isFetching}
        isFullScreen
      />
      {isShowPickDebtorDialog && (
        <PickDebtorNumberDialog
          onClose={() => {
            setContactId(undefined);
            setIsShowPickDebtorDialog(false);
          }}
          onSubmit={handlePickDebtor}
          contactId={contactId}
          recordId={0}
          recordType={RECORDTYPE.HealthManager_Premises}
        />
      )}
      {isShowRenewalOptionDialog && (
        <HMPremisesRenewalOptionsDialog
          onClose={() => {
            setIsShowRenewalOptionDialog(false);
          }}
          initialData={objRenewalOption}
          onSubmitDialog={handleSubmitRenewalOption}
          isLoading={hmPremisesRegisterSlider.isFetching}
        />
      )}
      {isShowIssueRegistrationDialog && (
        <DateToFromDialog
          onClose={() => setIsShowIssueRegistrationDialog(false)}
          onSubmit={handleSubmitIssueRegistration}
          isLoading={hmPremisesRegisterSlider.isFetching}
          data={objIssueRegistrationDate}
        />
      )}
    </>
  ) : null;
});

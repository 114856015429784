import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddConditionWithParentButton } from "@app/core/condition/components/buttons/add-condition/_index";
import { AddStdConditionButton } from "@app/core/condition/components/buttons/add-standard-condition/_index";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { AlertsButton } from "@app/core/contacts/components/buttons/alert/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { HMPremisesRegisterButton } from "@app/products/hm/premises/[id]/components/buttons/register-premises/_index";
import { SavePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/save/_index";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { AccordionsHmPremises } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premises-accordions/_index";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { HM_PREMISES_GET_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IPremisesCCFormOptions,
  Premises,
  Premises_Status,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { checkIsInactivePremises } from "@app/products/hm/premises/[id]/util";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { renderBubbleUps } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ExistHMManagePremise = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { alertSelectedRows } = useAlertsAccordionStore();
  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });
  const allowSaveAndEdit = checkPermissions(FormAction.CORE_ALLOW_EDIT);
  const isAllowedRegistration = checkPermissions(
    FormAction.HM_REGISTERREGISTRATION
  );

  const { checkPermissions: checkPermissionsCore } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Alert,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const isInactive = checkIsInactivePremises();

  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const premiseObj = dataForms?.GeneralForm as Svc_Premises;
  const premiseData = premiseObj?.Premises as Premises;
  const premisesCCFormOptions: IPremisesCCFormOptions =
    dataForms?.CCFormOptions;

  const { settings } = useCommonCoreStore();
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: "Health Manager - Premises",
      LinkUrl: managePageUrl,
      LinkText: `Heath Manager - Premises - ${premiseData?.Premises_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HealthManager,
      RecordType_ENUM: RECORDTYPE.HealthManager_Premises,
      Record_ID: premiseData?.Premises_ID ? +premiseData?.Premises_ID : 0,
    },
  ];

  const generateBadge = (initStatus?: string) => {
    const listBadge = initStatus ? [initStatus] : [];
    if (premiseData?.Status_ENUM) {
      listBadge.push(Premises_Status[premiseData.Status_ENUM]);
    }
    if (premiseData?.Alert) {
      listBadge.push("Alert");
    }
    return listBadge;
  };

  const title = useMemo(() => {
    let result = premiseData?.PremisesType?.PremisesClassification?.Name ?? "";
    if (premiseData?.RegistrationNumber) {
      result += ` - ${premiseData?.RegistrationNumber}`;
    }
    return result;
  }, [
    premiseData?.PremisesType?.PremisesClassification?.Name,
    premiseData?.RegistrationNumber,
  ]);

  const isDisabledRegisterPremisesBtn = useMemo(() => {
    return isInactive || !isAllowedRegistration;
  }, [isAllowedRegistration, isInactive]);

  const workflowButtonMemorized = useMemo(() => {
    return (
      <CCNavButton
        title={"Workflow"}
        className="cc-workflow-button"
        type="sub-workflow"
      >
        <CCNavButton title="Alerts" type="dropdown">
          <AlertsButton
            recordType={RECORDTYPE.HealthManager_Premises}
            onReload={reloadAlertHM}
            contactId={premiseData?.Premises_ID}
            isDisabled={
              !checkPermissionsCore(FormAction.CORE_ALLOW_NEW) || isInactive
            }
          />
          <AlertsButton
            isModifyButton
            isDisabled={
              !checkPermissionsCore(FormAction.CORE_ALLOW_EDIT) ||
              alertSelectedRows?.length !== 1 ||
              isInactive
            }
            recordType={RECORDTYPE.HealthManager_Premises}
            onReload={reloadAlertHM}
            contactId={premiseData?.Premises_ID}
          />
        </CCNavButton>
        <HMPremisesRegisterButton isDisabled={isDisabledRegisterPremisesBtn} />
      </CCNavButton>
    );
  }, [
    alertSelectedRows?.length,
    checkPermissionsCore,
    isDisabledRegisterPremisesBtn,
    isInactive,
    premiseData?.Premises_ID,
  ]);

  const validateOnClickConditionsBtn = () => {
    if (!premisesCCFormOptions?.PremisesType?.Flag_Conditions) {
      pushNotificationPortal({
        title:
          "Conditions are not permitted on the premises type: " +
          premisesCCFormOptions?.PremisesType?.Name,
        type: "warning",
        autoClose: true,
      });
      return false;
    }
    return true;
  };

  const reloadAlertHM = async () => {
    await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
  };
  return (
    <>
      <FormTitle title={title} badge={generateBadge()} />
      <CCManagePageActionBar
        leftActions={[
          workflowButtonMemorized,
          <SavePremisesButton isDisabled={isInactive || !allowSaveAndEdit} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION) ||
                isInactive
              }
            />
            <AdditionalContactButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT) ||
                isInactive
              }
            />
            <AddAttachmentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT) ||
                isInactive
              }
            />
            <AddCommentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT) ||
                isInactive
              }
            />
            <CommunicationButtonListView
              recordId={premiseData?.Premises_ID}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              recordType={RECORDTYPE.HealthManager_Premises}
              isDisabled={
                // !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION) || not security in Radium
                isInactive
              }
            />
            <CCNavButton
              title="Conditions"
              id={`cc-nav-${premiseData?.Premises_ID}`}
              type="dropdown"
            >
              <AddConditionWithParentButton
                id={premiseData?.Premises_ID}
                recordType={RECORDTYPE.HealthManager_Premises}
                productType={PRODUCT_TYPE_NUMBER.HealthManager}
                bubbleUps={renderBubbleUps(
                  premiseData?.Premises_ID,
                  RECORDTYPE.HealthManager_Premises
                )}
                isCondition
                isDisabled={isInactive}
                title="Custom"
                validateOnClick={validateOnClickConditionsBtn}
              />
              <AddStdConditionButton
                title="Standard"
                recordType={RECORDTYPE.HealthManager_Premises}
                productType={PRODUCT_TYPE_NUMBER.HealthManager}
                recordID={premiseData?.Premises_ID}
                isDisabled={isInactive}
                validateOnClick={validateOnClickConditionsBtn}
              />
            </CCNavButton>
            <AddInvoiceWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              isDisabled={isInactive}
              isVisible={isEnableMultiLineInvoice}
            />
            <AddDocumentButton
              dataSetFilter={MAILMERGEDATASET?.HealthManager_Premises}
              recordType={RECORDTYPE.HealthManager_Premises}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              id={premiseData?.Premises_ID}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT) ||
                isInactive
              }
            />
            <AddNoticeWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE) ||
                isInactive
              }
              validateOnClick={() => {
                if (!premisesCCFormOptions?.PremisesType?.Flag_Notices) {
                  pushNotificationPortal({
                    title:
                      "Notices are not permitted on the premises type: " +
                      premisesCCFormOptions?.PremisesType?.Name,
                    type: "warning",
                    autoClose: true,
                  });
                  return false;
                }
                return true;
              }}
            />
            <AddRelatedButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={isInactive}
            />
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={isShowSlideBar}
            onClick={() => {
              setIsShowSlideBar(!isShowSlideBar);
            }}
          />,

          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <CCAppNotification />
          <div className="cc-manage-form-body">
            {premiseData && <PremiseForm />}
            <AccordionsHmPremises
              premiseData={premiseData}
              isRecordDeleted={isInactive}
            />
          </div>
        </div>

        {isShowSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                { title: "Details", component: <PremiseDetailsTab /> },
                {
                  title: "Map",
                  component: (
                    <MapTab
                      address={premiseData?.SiteAddress?.Formatted_SingleLine}
                      zoom={18}
                      objViewOnMapRequest={
                        {
                          ReturnControllName: "GISKey",
                          GISKey: premiseData?.SiteAddress?.AssessmentNo ?? "",
                          ObjAddress: premiseData?.SiteAddress,
                          Module: "",
                          Layer: "",
                          ProductType: ProductType.HealthManager,
                        } as Svc_ViewOnMap
                      }
                      isInManagePage
                    />
                  ),
                },
                { title: "Contacts", component: <PremiseContactsTab /> },
                {
                  title: "History",
                  component: <PremiseHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
